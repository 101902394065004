import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../action';
import TransferFundPopUpPresentational from './TransferFundPopUpPresentational';
import { getLocalvariable, setLocalvariable } from '@helpers';

export default function TransferFundPopUpFunctional() {
	const dispatch = useDispatch();

	let rakbankDetails = useSelector((state) => state?.sharedStates?.rakbankdetails);
	let loader = useSelector((state) => state?.sharedStates?.isRakbankLoading);
	let postLoader = useSelector((state) => state?.payrollPlusState?.postRakBankDetailsLoading);
	const [isBankCardIsVisible, setIsBankCardIsVisible] = useState(false);

	const transferFundsPopupVisible = getLocalvariable('transferFundsPopupVisible');

	useEffect(() => {
		setIsBankCardIsVisible(JSON.parse(transferFundsPopupVisible));
	}, [transferFundsPopupVisible]);

	let [initialAccNumber, setInitialAccNumber] = useState('');
	const [transferCode, setTransferCode] = useState('');
	const [errorMessage, setErrorMessage] = useState(false);
	const [rakbankInfo, setRakbankInfo] = useState({ transferCode: {}, accountNumber: {} });
	const [accNumber, setAccNumber] = useState('');
	const [showTransferCode, setShowTransferCode] = useState(true);
	useEffect(() => {
		if (rakbankDetails.length > 0 && typeof rakbankDetails !== 'string') {
			rakbankDetails.map((res) => {
				if (res.nonRakBank === true) {
					rakbankInfo.transferCode = res;
					setTransferCode(res.accountNumber);
				} else {
					rakbankInfo.accountNumber = res;
					setShowTransferCode(false);
					setInitialAccNumber(res.accountNumber);
					setAccNumber(res.accountNumber);
				}
			});
			setRakbankInfo(rakbankInfo);
		}
	}, [rakbankDetails]);
	const createRakankAccDetails = (res) => {
		setAccNumber(res);
		if (res.toString().length == 13) {
			setErrorMessage(false);
		} else {
			setErrorMessage(true);
		}
	};
	const postBankDetails = (mode, res) => {
		let bankDetailPayload = {};
		if (mode === 'Done') {
			if (rakbankInfo?.accountNumber?.accountNumber) {
				bankDetailPayload = rakbankInfo.accountNumber;
				bankDetailPayload.accountNumber = res;
			} else {
				bankDetailPayload = rakbankInfo.transferCode;
				bankDetailPayload.accountNumber = res;
				delete bankDetailPayload?.id;
			}
			bankDetailPayload.nonRakBank = false;
			dispatch(Actions.creators.postRakBankDetails(bankDetailPayload));
			setIsBankCardIsVisible(false);
		} else if (mode === 'Understood') {
			if (rakbankInfo?.transferCode?.accountNumber) {
				bankDetailPayload = rakbankInfo.transferCode;
			}
			bankDetailPayload.nonRakBank = true;
			dispatch(Actions.creators.postRakBankDetails(bankDetailPayload));
			setIsBankCardIsVisible(false);
		}
		dispatch(Actions.creators.showTransferFundPopup(false));
		setLocalvariable('transferFundsPopupVisible', false);
	};

	return (
		<TransferFundPopUpPresentational
			{...{
				rakbankInfo,
				accNumber,
				transferCode,
				setAccNumber,
				showTransferCode,
				setShowTransferCode,
				createRakankAccDetails,
				errorMessage,
				loader,
				postBankDetails,
				setIsBankCardIsVisible,
				isBankCardIsVisible,
				postLoader,
				initialAccNumber,
			}}></TransferFundPopUpPresentational>
	);
}
