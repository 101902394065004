import React from 'react';
import { icons } from 'src/assets/icons';
import ImageComponent from '@sharedComponent/image-component';

const FilterValue = ({ filterValue, filterLabel, handleFilterData }) => {
	return (
		<div className="filter_value_container">
			{filterValue?.map((data, index) => {
				return (
					<div
						key={index}
						className="filter_value"
						onClick={() =>
							handleFilterData(
								filterLabel?.filterLabel,
								data?.filterValue,
								data?.value,
								filterLabel?.labelKey,
								data?.valueKey,
								index,
								!data?.isSelected
							)
						}>
						{data?.isLoading ? (
							<div className="Loader" />
						) : (
							<>
								<ImageComponent src={data?.isSelected ? icons?.focus_state?.filterCheckIn : icons?.focus_state?.filterCheckOut} />
							</>
						)}
						<p className={data?.isSelected ? 'filter_selected' : 'filter_not_selected'}>{data?.filterValue}</p>
					</div>
				);
			})}
		</div>
	);
};

export default FilterValue;
