import ClevertapReact from 'src/utils/clever-tap';
import { debounceHandler, entriesDropDown } from '@helpers';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ETypo from '@pages/DesignSystem/Typo';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import FilterButton from '@pages/DesignSystem/filter';
import ImageComponent from '@sharedComponent/image-component';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { LoadingOutlined } from '@ant-design/icons';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PayrollPlusNewTableHeader = ({
	payrollTableLoader = false,
	onChangeHandler,
	handlePagination,
	requestParams,
	handlePayrollPlusFilterSelect,
	handleSelectAll,
	dropDownValueState,
	setIsAllSelected,
	setDropDownValueState,
	uploadSpreadsheetShow = true,
}) => {
	// const { payrollPlusDataLoader } = useSelector((state) => state?.counter);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const currentSelectedLabel = useRef('');
	const history = useHistory();
	const hrOnBoardingFlowCompleted = useSelector((state) => state?.sharedStates?.userNavigation?.hrOnBoardingFlowCompleted) || false;
	const nFilterData = [
		{
			filterLabel: 'Payroll Channel',
			count: 0,
			filterValue: dropDownValueState?.[0]?.options?.map((data) => {
				return {
					...data,
					filterValue: data?.payrollChannelName,
				};
			}),
		},
		{
			filterLabel: 'Account Type',
			count: 0,
			filterValue: dropDownValueState?.[1]?.options?.map((data) => {
				return {
					...data,
					filterValue: data?.accountTypeName,
				};
			}),
		},
		{
			filterLabel: 'Establishment ID',
			count: 0,
			filterValue: dropDownValueState?.[2]?.options?.map((data) => {
				return {
					...data,
					filterValue: data?.establishmentId,
				};
			}),
		},
		{
			filterLabel: 'Bank Name',
			count: 0,
			filterValue: dropDownValueState?.[3]?.options?.map((data) => {
				return {
					...data,
					filterValue: data?.bankName,
				};
			}),
		},
	];

	const onFilterValueSelected = (data) => {
		handlePayrollPlusFilterSelect(data);
		const result = [...selectedFilters];
		let indexOfSelectedValue = result.indexOf(data.value);
		currentSelectedLabel.current = data.label;
		if (indexOfSelectedValue > -1) {
			result.splice(indexOfSelectedValue, 1);
		} else {
			result.push(data.value);
		}
		setSelectedFilters(result);
	};

	const clearAllFilters = () => {
		setIsAllSelected(false);
		let lDropDownValueState = (dropDownValueState || [])?.map((dropdown) => {
			let tempOption = (dropdown?.options || [])?.map((option) => {
				return {
					...option,
					isSelected: false,
				};
			});
			const isAllSelected = tempOption?.filter((value) => value?.isSelected)?.length === tempOption?.length;
			return {
				...dropdown,
				isAllSelected,
				options: tempOption,
			};
		});
		setDropDownValueState([...lDropDownValueState]);
		setSelectedFilters([]);
		currentSelectedLabel.current = '';
	};
	return (
		<div className="payroll_plus_new_table_header">
			{uploadSpreadsheetShow ? (
				<>
					<div className="payroll-plus-filters">
						<div style={{ width: '40%' }} onClick={() => ClevertapReact.event('Search_PayrollPlusTable')}>
							<Inputbox
								className="payrollPlus-search"
								prefix={
									payrollTableLoader ? (
										<div
											style={{
												width: 25,
												height: 25,
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<LoadingOutlined className="search-icon" />
										</div>
									) : (
										<ImageComponent className="search-icon" src={focus_state_icons.NewSearch} />
									)
								}
								placeholder="Search by Name or Employee ID."
								onChange={(e) => debounceHandler(e.target.value, onChangeHandler)}
							/>
						</div>
						<FilterButton
							{...{
								value: selectedFilters,
								filterData: nFilterData,
								openedFilter: currentSelectedLabel.current,
								selectedFilter: onFilterValueSelected,
								filterClearAll: () => clearAllFilters(),
								onClick: () => ClevertapReact.event('Filters_PayrollPlustable'),
							}}
						/>
						{/* <EdenredButtons
							leftIcon={<ImageComponent src={not_focus_icons?.uploadSpreadsheet} />}
							btnType="secondary"
							className="payroll-plus-previous"
							disabled={payrollPlusDataLoader}
							toolTipClassName="width_fit_content"
							onClick={() => {
								ClevertapReact.event('Uploadexcel_PayrollTable', null);
								hrOnBoardingFlowCompleted !== true
									? history.push({
											pathname: '/uploadSpreadsheetSetup',
											state: { payrollService: 'PAYROLL-PLUS' },
									  })
									: history.push({
											pathname: '/uploadSpreadsheetPlus',
											state: { payrollService: 'PAYROLL-PLUS' },
									  });
							}}>
							Upload Spreadsheet
						</EdenredButtons> */}
					</div>

					<div className="payroll_plus_new_entries">
						<ETypo light b2 color="#484B52">
							Show
						</ETypo>
						<div style={{ width: '50px' }}>
							<MultiSelect
								{...{
									Option: entriesDropDown,
									value: requestParams?.pageSize || 1,
									dropDownValue: 'value',
									searchVisibility: false,
									onChange: (page) => handlePagination(page?.value, 'pageSize'),
								}}
							/>
						</div>
						<ETypo light b2 color="#484B52">
							Entries
						</ETypo>
					</div>
				</>
			) : (
				''
			)}
			{/* {payrollFilter && (
				<Row ref={ref} className="payroll-plus-filters-popup">
					{dropDownValueState.map((data, index) => (
						<Col xl={6} lg={12} md={12} sm={24} className="payroll-plus-new-filters-container">
							<Col xl={24} lg={24} md={24} sm={24} key={index} className="payroll-plus-new-filters-inside">
								<Dropdown
									overlay={
										<FilterContents
											{...{
												name: data?.name || '',
												isAllSelected: data?.isAllSelected || false,
												isSelected: data,
												options: data?.options || [],
												filterContents: dropDownValueState,
												handleChange,
												handleSelectAll,
											}}
										/>
									}
									visible={
										(data.name === 'Payroll Channel' && arrow.PayrollChannel && payrollFilter) ||
										(data.name === 'Account Type' && arrow.AccountType && payrollFilter) ||
										(data.name === 'Establishment ID' && arrow.EstablishmentID && payrollFilter) ||
										(data.name === 'Bank Name' && arrow.BankName && payrollFilter)
									}
									trigger={['click']}
									className="payroll-plus-new-filtersPopup">
									<EdenredButton type="outline-g" className="payroll-plus-filter-tiles" onClick={() => handleButton(data.name)}>
										{`${data.name}(${
											(data.name === 'Payroll Channel' ? (payrollcount === undefined ? 0 : payrollcount) : 0) ||
											(data.name === 'Account Type' ? (accountcount === undefined ? 0 : accountcount) : 0) ||
											(data.name === 'Establishment ID' ? (establishmentcount === undefined ? 0 : establishmentcount) : 0) ||
											(data.name === 'Bank Name' ? (bankcount === undefined ? 0 : bankcount) : 0)
										})`}
										{(data.name === 'Payroll Channel' && arrow.PayrollChannel) ||
										(data.name === 'Account Type' && arrow.AccountType) ||
										(data.name === 'Establishment ID' && arrow.EstablishmentID) ||
										(data.name === 'Bank Name' && arrow.BankName) ? (
											<ImageComponent className="fil-image" src={icons.not_focus.up} />
										) : (
											<ImageComponent className="fil-image" src={icons.not_focus.down} />
										)}
									</EdenredButton>
								</Dropdown>
							</Col>
						</Col>
					))}

					<Col xl={24} lg={24} md={24}>
						<Col className="payroll-plus-clearAll">
							<EdenredTypo className="payroll-plus-clearAll-bottomOne b3" onClick={() => handleSelectAll(null, true, true)}>
								Clear All Filters <span>{selectcount}</span>
							</EdenredTypo>
							<EdenredTypo className="payroll-plus-clearAll-bottomTwo b3" onClick={() => handleSelectAll(null, true)}>
								Select All Filters
							</EdenredTypo>
						</Col>
					</Col>
				</Row>
			)} */}
		</div>
	);
};

export default PayrollPlusNewTableHeader;
